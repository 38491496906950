import { useQuery } from '@tanstack/react-query';
import { MarketplaceAPI } from 'revibe-api';
import { useSession, signIn, signOut } from 'next-auth/react';
import { useTranslation } from 'i18n/hooks';
import { useState } from 'react';
import { selectAuthModalIsOpen, toggleAuthModal } from 'redux/features/modals';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useToast } from 'modules/common/hooks';

interface CredentialsLogin {
  email: string;
  password: string;
}

interface LoginCallback {
  onError?: (error: string) => void;
  onSuccess?: () => void;
}

export const useAuth = () => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const { language } = useTranslation();
  const { toast } = useToast();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();
  const isAuthModalOpen = useSelector(selectAuthModalIsOpen);
  const closeAuthModal = () => dispatch(toggleAuthModal(false));
  const openAuthModal = () => dispatch(toggleAuthModal(true));

  const {
    data: user,
    isLoading,
    refetch,
  } = useQuery(
    ['user', session?.user.id],
    async () => {
      if (!session) {
        return null;
      }
      const { data, error } = await MarketplaceAPI.getUser(
        String(session.user.id),
        language
      );
      if (error) {
        throw new Error(error);
      }

      if (!data) {
        return null;
      }

      return data;
    },
    {
      enabled: session?.user.id !== undefined,
    }
  );

  const loginWithCredentials = async (
    { email, password }: CredentialsLogin,
    { onError, onSuccess }: LoginCallback = {}
  ) => {
    setIsLoggingIn(true);
    const res = await signIn('credentials', {
      username: email,
      password,
      callbackUrl: '/',
      redirect: false,
    });
    if (!res || res.error) {
      if (onError) {
        onError(res?.error || 'unknown');
      }
    } else {
      if (onSuccess) {
        onSuccess();
      }
    }
    setIsLoggingIn(false);
  };

  const loginWithFacebook = async ({
    onError,
    onSuccess,
  }: LoginCallback = {}) => {
    setIsLoggingIn(true);
    await signIn('facebook', {
      callbackUrl: router.asPath,
      redirect: false,
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        toast('logged-in');
        setIsLoggingIn(false);
      },
      onError: (error: any) => {
        if (onError) {
          onError(error);
        }
        setIsLoggingIn(false);
      },
    });
  };

  return {
    user: user || null,
    userID: session?.user ? String(session.user.id) : null,
    isLoggingIn,
    isLoggedIn: status === 'authenticated',
    isLoadingUser: isLoading,
    loginWithCredentials,
    loginWithFacebook,
    logout: signOut,
    refetchUser: refetch,
    authModal: {
      isOpen: isAuthModalOpen,
      open: openAuthModal,
      close: closeAuthModal,
    },
  };
};
