import { MarketplaceAPI } from 'revibe-api';

export const FB_EVENT = {
  ADD_PAYMENT_INFO: 'FB_AddPaymentInfo',
  ADD_TO_CART: 'FB_AddToCart',
  ADD_TO_WISHLIST: 'FB_AddToWishlist',
  COMPLETE_REGISTRATION: 'FB_CompleteRegistration',
  CONTACT: 'FB_Contact',
  INITIATE_CHECKOUT: 'FB_InitiateCheckout',
  LEAD: 'FB_Lead',
  PURCHASE: 'FB_Purchase',
  SEARCH: 'FB_Search',
  VIEW_CONTENT: 'FB_ViewContent',
} as const;

type FBContent = {
  id: string;
  quantity: number;
};

namespace FBPayload {
  export type AddPaymentInfo = {
    value: number;
    currency: string;
    content_ids?: string[]; // Product IDs associated with the event, such as SKUs
    content_category?: string;
    contents?: FBContent;
  };

  export type AddToCart = {
    value: number;
    currency: string;
    content_ids: string[]; // Product IDs associated with the event, such as SKUs
    content_name: string; // Name of the page/product.
    content_type: string;
    contents: FBContent[];
  };

  export type AddToWishlist = {
    value: number;
    currency: string;
    content_name: string;
    content_ids: string[]; // Product IDs associated with the event, such as SKUs
    content_category: string;
    contents: FBContent[];
  };

  export type InitiateCheckout = {
    value: number;
    currency: string;
    content_ids: string[]; // Product IDs associated with the event, such as SKUs
    num_items: number;
    content_category?: string;
    contents: FBContent[];
  };

  export type Purchase = {
    value: number;
    currency: string;
    num_items: number;
    content_ids: string[]; // Product IDs associated with the event, such as SKUs
    contents: FBContent[];
    content_type?: string;
    content_name?: string;
  };

  export type ViewContent = {
    value: number;
    currency: string;
    content_ids: string[]; // Product IDs associated with the event, such as SKUs
    content_type: string;
    content_name: string;
    content_category: string;
    contents: FBContent[];
  };

  export type Search = {
    content_ids?: string[];
    content_category?: string;
    contents?: FBContent[];
    currency?: string;
    value?: number;
    search_string: string;
  };

  export type Lead = {
    content_category?: string;
    content_name?: string;
    currency?: string;
    value?: number;
  };
}

export type FBEvent =
  | {
      event: typeof FB_EVENT.ADD_PAYMENT_INFO;
      payload: FBPayload.AddPaymentInfo;
    }
  | {
      event: typeof FB_EVENT.ADD_TO_CART;
      payload: FBPayload.AddToCart;
    }
  | {
      event: typeof FB_EVENT.ADD_TO_WISHLIST;
      payload: FBPayload.AddToWishlist;
    }
  | {
      event: typeof FB_EVENT.INITIATE_CHECKOUT;
      payload: FBPayload.InitiateCheckout;
    }
  | {
      event: typeof FB_EVENT.PURCHASE;
      payload: FBPayload.Purchase;
    }
  | {
      event: typeof FB_EVENT.VIEW_CONTENT;
      payload: FBPayload.ViewContent;
    }
  | {
      event: typeof FB_EVENT.SEARCH;
      payload: FBPayload.Search;
    }
  | {
      event: typeof FB_EVENT.LEAD;
      payload: FBPayload.Lead;
    };

export const cartItemToFBContent = (
  cartItem: MarketplaceAPI.CartItem
): FBContent => ({
  id: String(cartItem.details.id),
  quantity: cartItem.amount,
});

export const itemToFBContent = (cartItem: MarketplaceAPI.Item): FBContent => ({
  id: String(cartItem.id),
  quantity: 1,
});

export const orderItemToFBContent = (
  cartItem: MarketplaceAPI.OrderItem
): FBContent => ({
  id: String(cartItem.itemID),
  quantity: 1,
});
