import React from 'react';

import { cn } from 'revibe-ui';

type Props = {
  children: React.ReactNode;
  className?: string;
  centered?: boolean;
  minHeight?: boolean;
  noPaddingMobile?: boolean;
};

export const Layout = ({
  children,
  className = '',
  centered = false,
  minHeight = false,
  noPaddingMobile = false,
}: Props) => {
  return (
    <div className={cn('relative flex bg-white', className)}>
      <div
        className={cn(
          'box-border w-full',
          className,
          minHeight && 'min-h-[80vh]',
          noPaddingMobile ? 'px-0 py-4 lg:px-4' : 'p-4',
          centered && 'flex flex-col items-center justify-center'
        )}
      >
        {children}
      </div>
    </div>
  );
};
