import { useEffect } from 'react';

import GTM from 'react-gtm-module';

import { getIsProduction, isBrowserEnv } from 'modules/common/utils/env';

import { ObjectType } from '../../common/utils/types';
import {
  GAEvent,
  GA_EVENT,
  cartItemToGACommerceItem,
  itemToGACommerceItem,
  orderItemToGACommerceItem,
} from '../utils/googleAnalyticsEvents';
import { useConsent } from './useConsent';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { MarketplaceAPI } from 'revibe-api';
import {
  cartItemToFBContent,
  FBEvent,
  FB_EVENT,
  itemToFBContent,
  orderItemToFBContent,
} from '../utils/facebookEvents';
import {
  KLAVIYO_EVENT,
  KlaviyoEvent,
  cartItemToKlaviyoCartItem,
  itemToKlaviyoItem,
} from '../utils/klaviyoEvents';

const generateEventID = () => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const useDataLayer = () => {
  const { hasFBConsent } = useConsent();
  const { user } = useAuth();

  const pushToDataLayer = (dlData: ObjectType<any>) => {
    if (!isBrowserEnv()) {
      return;
    }

    if (!getIsProduction()) {
      console.log(`[GTM] Pushing to dataLayer:`, dlData);
      return;
    }

    GTM.dataLayer({
      dataLayer: {
        user: user
          ? {
              id: String(user.id),
              email: user.email,
              name: user.name,
            }
          : {},
        facebook: {
          event_id: generateEventID(),
          consent: hasFBConsent,
        },
        ...dlData,
      },
    });
  };

  useEffect(() => {
    pushToDataLayer({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFBConsent]);

  const trackException = (errorDescription: string) => {
    pushToDataLayer({
      event: 'exception',
      error: {
        description: errorDescription,
      },
    });
  };

  const pushGAEvent = ({ event, payload }: GAEvent) => {
    try {
      pushToDataLayer({
        ecommerce: null,
      });

      pushToDataLayer({
        ecommerce: payload,
        event,
      });
    } catch (_) {
      trackException('Error sending GA event');
    }
  };

  const pushFBEvent = ({ event, payload }: FBEvent) => {
    try {
      pushToDataLayer({
        facebook: null,
      });

      pushToDataLayer({
        event,
        facebook: {
          properties: payload,
          event_id: generateEventID(),
          consent: hasFBConsent,
        },
      });
    } catch (_) {
      trackException('Error sending FB event');
    }
  };

  const pushKlaviyoEvent = ({ event, payload }: KlaviyoEvent) => {
    try {
      pushToDataLayer({
        klaviyo: null,
      });

      pushToDataLayer({
        event,
        klaviyo: payload,
      });
    } catch (_) {
      trackException('Error sending Klaviyo event');
    }
  };

  const trackViewItemList = (
    listID: string,
    listName: string,
    products: MarketplaceAPI.Item[]
  ) => {
    pushGAEvent({
      event: GA_EVENT.VIEW_ITEM_LIST,
      payload: {
        item_list_id: listID,
        item_list_name: listName,
        items: products.map((product) =>
          itemToGACommerceItem({
            ...product,
            list: listName,
          })
        ),
      },
    });
  };

  const trackSelectItem = (
    listID: string,
    listName: string,
    product: MarketplaceAPI.Item
  ) => {
    pushGAEvent({
      event: GA_EVENT.SELECT_ITEM,
      payload: {
        item_list_id: listID,
        item_list_name: listName,
        items: [
          itemToGACommerceItem({
            ...product,
            list: listName,
          }),
        ],
      },
    });
  };

  const trackViewItem = (product: MarketplaceAPI.Item) => {
    pushGAEvent({
      event: GA_EVENT.VIEW_ITEM,
      payload: {
        value: product.selling_price || product.price,
        currency: 'EUR',
        items: [itemToGACommerceItem(product)],
      },
    });

    pushFBEvent({
      event: FB_EVENT.VIEW_CONTENT,
      payload: {
        content_ids: [String(product.id)],
        content_type: 'product',
        value: product.selling_price || product.price,
        content_name: product.name,
        contents: [itemToFBContent(product)],
        content_category: product.category.name,
        currency: 'EUR',
      },
    });

    pushKlaviyoEvent({
      event: KLAVIYO_EVENT.VIEW_ITEM,
      payload: itemToKlaviyoItem(product),
    });
  };

  const trackAddToCart = (product: MarketplaceAPI.CartItem) => {
    pushGAEvent({
      event: GA_EVENT.ADD_TO_CART,
      payload: {
        value: product.discounted_price || product.price,
        currency: 'EUR',
        items: [cartItemToGACommerceItem(product)],
      },
    });

    pushFBEvent({
      event: FB_EVENT.ADD_TO_CART,
      payload: {
        content_ids: [String(product.details.id)],
        content_type: 'product',
        value: product.discounted_price || product.price,
        content_name: product.details.name,
        contents: [cartItemToFBContent(product)],
        currency: 'EUR',
      },
    });

    pushKlaviyoEvent({
      event: KLAVIYO_EVENT.ADD_TO_CART,
      payload: {
        $value: product.discounted_price || product.price,
        AddedItemProductName: product.details.name,
        AddedItemProductID: String(product.details.id),
        AddedItemSKU: String(product.details.id),
        AddedItemCategories: [product.details.category.name],
        AddedItemImageURL: product.details.photos[0].medium_link,
        AddedItemURL: `www.revibe-upcycling.com/shop/item/${product.details.slug}`,
        AddedItemPrice: product.discounted_price || product.price,
        AddedItemQuantity: 1,
        ItemNames: [product.details.name],
        CheckoutURL: 'www.revibe-upcycling.com/checkout/delivery-configuration',
        Items: [cartItemToKlaviyoCartItem(product)],
      },
    });
  };

  const trackRemoveFromCart = (product: MarketplaceAPI.CartItem) => {
    pushGAEvent({
      event: GA_EVENT.REMOVE_FROM_CART,
      payload: {
        value: product.discounted_price || product.price,
        currency: 'EUR',
        items: [cartItemToGACommerceItem(product)],
      },
    });
  };

  const trackViewCart = (cart: MarketplaceAPI.Cart) => {
    pushGAEvent({
      event: GA_EVENT.VIEW_CART,
      payload: {
        value: cart.subtotal.total,
        currency: 'EUR',
        items: cart.items.map(cartItemToGACommerceItem),
      },
    });
  };

  const trackBeginCheckout = (
    cart: MarketplaceAPI.Cart & { itemsCount: number }
  ) => {
    pushGAEvent({
      event: GA_EVENT.BEGIN_CHECKOUT,
      payload: {
        value: cart.subtotal.total,
        currency: 'EUR',
        items: cart.items.map(cartItemToGACommerceItem),
        coupon: cart.promo?.name,
      },
    });

    pushFBEvent({
      event: FB_EVENT.INITIATE_CHECKOUT,
      payload: {
        content_ids: cart.items.map((item) => String(item.details.id)),
        num_items: cart.itemsCount,
        value: cart.subtotal.total,
        contents: cart.items.map(cartItemToFBContent),
        currency: 'EUR',
      },
    });

    pushKlaviyoEvent({
      event: KLAVIYO_EVENT.INITIATED_CHECKOUT,
      payload: {
        $event_id: String(cart.id),
        ItemNames: cart.items.map((item) => item.details.name),
        Categories: cart.items.map((item) => item.details.category.name),
        $value: cart.subtotal.total,
        Items: cart.items.map(cartItemToKlaviyoCartItem),
        CheckoutURL: 'www.revibe-upcycling.com/checkout/delivery-configuration',
      },
    });
  };

  const trackAddShippingInfo = (cart: MarketplaceAPI.Cart) => {
    pushGAEvent({
      event: GA_EVENT.ADD_SHIPPING_INFO,
      payload: {
        value: cart.subtotal.total,
        currency: 'EUR',
        coupon: cart.promo?.name,
        shipping_tier: cart.delivery.type,
        items: cart.items.map(cartItemToGACommerceItem),
      },
    });
  };

  const trackAddPaymentInfo = (cart: MarketplaceAPI.Cart) => {
    pushGAEvent({
      event: GA_EVENT.ADD_PAYMENT_INFO,
      payload: {
        value: cart.subtotal.total,
        currency: 'EUR',
        coupon: cart.promo?.name,
        payment_type: 'Card',
        items: cart.items.map(cartItemToGACommerceItem),
      },
    });

    pushFBEvent({
      event: FB_EVENT.ADD_PAYMENT_INFO,
      payload: {
        value: cart.subtotal.total,
        currency: 'EUR',
      },
    });
  };

  const trackAddToWishList = (product: MarketplaceAPI.Item) => {
    pushGAEvent({
      event: GA_EVENT.ADD_TO_WISHLIST,
      payload: {
        value: product.selling_price || product.price,
        currency: 'EUR',
        items: [itemToGACommerceItem(product)],
      },
    });

    pushFBEvent({
      event: FB_EVENT.ADD_TO_WISHLIST,
      payload: {
        content_ids: [String(product.id)],
        value: product.selling_price || product.price,
        content_name: product.name,
        content_category: product.category.name,
        contents: [itemToFBContent(product)],
        currency: 'EUR',
      },
    });
  };

  const trackPurchase = (order: MarketplaceAPI.MarketplaceOrder) => {
    pushGAEvent({
      event: GA_EVENT.PURCHASE,
      payload: {
        value: order.subtotal_post_discount,
        currency: 'EUR',
        coupon: order.promo?.name,
        transaction_id: order.order_id,
        shipping: order.delivery_fees,
        tax: 0,
        items: order.items.map(orderItemToGACommerceItem),
      },
    });

    pushFBEvent({
      event: FB_EVENT.PURCHASE,
      payload: {
        content_ids: order.items.map((item) => String(item.itemID)),
        value: order.subtotal_post_discount,
        currency: 'EUR',
        contents: order.items.map(orderItemToFBContent),
        content_type: 'product',
        content_name: order.items.map((item) => item.name).join(', '),
        num_items: order.items.length,
      },
    });
  };

  const trackSearch = (searchTerm: string) => {
    pushFBEvent({
      event: FB_EVENT.SEARCH,
      payload: {
        search_string: searchTerm,
      },
    });
  };

  const trackLead = () => {
    pushFBEvent({
      event: FB_EVENT.LEAD,
      payload: {},
    });
  };

  return {
    trackViewItemList,
    trackSelectItem,
    trackViewItem,
    trackAddToCart,
    trackRemoveFromCart,
    trackViewCart,
    trackAddToWishList,
    trackBeginCheckout,
    trackAddShippingInfo,
    trackAddPaymentInfo,
    trackPurchase,
    trackSearch,
    trackLead,
    trackException,
  };
};
