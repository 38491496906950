import { isBrowserEnv } from './env';

export const getIsDesktop = () => {
  if (isBrowserEnv()) {
    return window.matchMedia('(min-width: 1024px)').matches;
  }
  return false;
};
export const getIsMobile = () => {
  if (isBrowserEnv()) {
    return window.matchMedia('(max-width: 1024px)').matches;
  }
  return false;
};
