import React from 'react';

import { useTranslation } from 'i18n/hooks';
import { Button, cn } from 'revibe-ui';
import { SpacedContainer } from 'revibe-ui';

import { useToast } from 'modules/common/hooks';

import { Input } from 'ui/components/Input';
import { MarketplaceAPI } from 'revibe-api';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useDataLayer } from 'modules/tracking/hooks/useDataLayer';

export const NewsletterSubscription = ({
  className,
}: {
  className?: string;
}) => {
  const { tu, language } = useTranslation();
  const { errorToast, successToast } = useToast();
  const dataLayer = useDataLayer();
  const { mutate: subscribeUserToNewsletter } = useMutation(
    ['Subscribe to newsletter'],
    MarketplaceAPI.subscribeUserToNewsletter,
    {
      onError: errorToast,
      onSuccess: () => successToast('newsletter-subscribe-success'),
    }
  );

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  return (
    <form
      onSubmit={handleSubmit(async ({ email }) => {
        dataLayer.trackLead();
        await subscribeUserToNewsletter({ email, language });
      })}
      className={cn(
        'flex h-full w-full flex-col justify-between gap-3 bg-black p-4 text-center text-white',
        className
      )}
    >
      <p className="font-formula text-3xl uppercase">
        {tu('newsletter', 'common')}
      </p>
      <SpacedContainer spacing={'small'}>
        <p className="font-light">
          {tu('subscribe-heading', 'user.newsletter')}{' '}
        </p>
        <Input
          containerClassName="max-w-xl w-full mx-auto"
          className="bg-transparent"
          type={'email'}
          {...register('email', { required: true })}
          placeholder={tu('email', 'auth')}
        />
        <Button variant="subtle" className="mx-auto" isLoading={isSubmitting}>
          {tu('subscribe', 'user.newsletter')}
        </Button>
      </SpacedContainer>
      <p className="mx-auto max-w-lg text-xs text-gray-500">
        {tu('newsletter-disclaimer', 'common')}
      </p>
    </form>
  );
};
