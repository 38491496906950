import React from 'react';

import { cn } from 'revibe-ui';
import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import { SpacedContainer } from 'revibe-ui';
import { BLOG_ROUTES, COMPANY_ROUTES, NEWS_ROUTES, SHOP_ROUTES } from 'routes';

import { LanguageSelector } from 'modules/common/components/LanguageSelector';
import { Layout } from 'modules/common/components/Layout';
import { NewsletterSubscription } from 'modules/user/components/NewsletterSubscription';

import { Link } from 'ui/components/Link';

type Props = {
  noTopSpace?: boolean;
};

export const Footer = ({ noTopSpace = false }: Props) => {
  const { tu } = useTranslation(I18N_NAMESPACES.COMMON);

  return (
    <footer className="text-white">
      <Layout className={cn('bg-black', noTopSpace ? '' : 'pt-8')}>
        <div className="flex grid-cols-2 flex-col gap-4 md:grid">
          <NewsletterSubscription className="border border-white" />
          <SpacedContainer className="justify-between">
            <div className="box-border flex flex-col items-center border border-white p-4 uppercase">
              <p className="font-formula text-3xl">{tu('social')}</p>
              <div className="flex flex-wrap justify-evenly gap-4">
                <Link
                  to="https://www.instagram.com/revibe_upcycling/"
                  target="_blank"
                  className="text-sm text-white hover:text-white"
                >
                  Instagram
                </Link>
                <Link
                  to="https://www.facebook.com/revibe-upcycling"
                  target="_blank"
                  className="text-sm text-white hover:text-white"
                >
                  Facebook
                </Link>
                <Link
                  to="https://www.linkedin.com/company/revibe-upcycling"
                  target="_blank"
                  className="text-sm text-white hover:text-white"
                >
                  LinkedIn
                </Link>
              </div>
            </div>
            <div className="border-green-fluo box-border flex flex-col items-center border p-2">
              <Link
                to={BLOG_ROUTES.HOME}
                hasDecoration={false}
                className="font-syne text-green-fluo font-semibold uppercase"
              >
                stain magazine
              </Link>
            </div>
            <div className="mt-6">
              <p className="font-formula text-3xl uppercase">
                {tu('about-revibe', 'common')}
              </p>
              <div className="flex grid-cols-2 flex-col gap-4 md:grid">
                <div className="flex flex-col">
                  <Link
                    className="text-sm uppercase text-white hover:text-white"
                    to={COMPANY_ROUTES.ABOUT_US}
                  >
                    {tu('about-us')}
                  </Link>
                  <Link
                    className="text-sm uppercase text-white hover:text-white"
                    to={SHOP_ROUTES.SELLERS}
                  >
                    {tu('our-brands')}
                  </Link>
                  <Link
                    className="text-sm uppercase text-white hover:text-white"
                    to={NEWS_ROUTES.HOME}
                  >
                    news
                  </Link>
                  <Link
                    className="text-sm uppercase text-white hover:text-white"
                    target="_blank"
                    to={`${process.env.NEXT_PUBLIC_SELLER_WEBSITE_URL}/services`}
                  >
                    {tu('selling-on-revibe')}
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link
                    className="text-sm uppercase text-white hover:text-white"
                    to={COMPANY_ROUTES.CONTACT_US}
                  >
                    {tu('contact-us.heading')}
                  </Link>
                  <Link
                    className="text-sm uppercase text-white hover:text-white"
                    to={COMPANY_ROUTES.PLATFORM}
                  >
                    {tu('b2boffer')}
                  </Link>
                  <Link
                    className="text-sm uppercase text-white hover:text-white"
                    to={COMPANY_ROUTES.DELIVERY_AND_RETURNS}
                  >
                    {tu('delivery-returns')}
                  </Link>
                  <Link
                    className="text-sm uppercase text-white hover:text-white"
                    to={COMPANY_ROUTES.PAYMENTS}
                  >
                    {tu('payments-guarantees')}
                  </Link>
                </div>
              </div>
            </div>
          </SpacedContainer>
        </div>
        <div className="my-8 flex w-full justify-between">
          <SpacedContainer spacing="small">
            <span className="text-sm">
              &copy; 2022 Revibe | {tu('all-rights-reserved', 'common')}
            </span>
            <Link
              className="text-sm text-white hover:text-white"
              to="/privacy-policy"
            >
              {tu('privacy-policy')}
            </Link>
            <Link
              className="text-sm text-white hover:text-white"
              to="/terms-of-service"
            >
              {tu('terms-of-service')}
            </Link>
            <Link
              className="text-sm text-white hover:text-white"
              to="/cookie-policy"
            >
              {tu('cookie-policy')}
            </Link>
          </SpacedContainer>
          <div className="justify-self-end">
            <LanguageSelector />
          </div>
        </div>
      </Layout>
    </footer>
  );
};
