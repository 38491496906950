import React from 'react';

import { Input as RevibeUIInput, cn } from 'revibe-ui';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: React.ReactNode;
  containerClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    return (
      <RevibeUIInput
        className={cn('rounded-none bg-gray-50', className)}
        {...props}
        ref={ref}
      />
    );
  }
);

Input.displayName = 'Input';

export { Input };
