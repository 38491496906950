import { useEffect, useState } from 'react';

import { isBrowserEnv } from '../utils/env';
import { getIsDesktop } from '../utils/mediaQuery';

export function useMediaQuery() {
  const [isDesktop, setIsDesktop] = useState(getIsDesktop());
  const [isMobile, setIsMobile] = useState(!getIsDesktop());

  useEffect(() => {
    const resizeListener = () => {
      setIsDesktop(getIsDesktop());
      setIsMobile(!getIsDesktop());
    };

    if (isBrowserEnv()) {
      window.addEventListener('resize', resizeListener);
    }

    return () => {
      if (isBrowserEnv()) {
        window.removeEventListener('resize', resizeListener);
      }
    };
  }, []);

  return { isMobile, isDesktop };
}
