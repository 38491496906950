import React from 'react';

import { useRouter } from 'next/router';

import { cn } from 'revibe-ui';
import { SUPPORTED_LOCALES, SupportedLocale } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import setLanguage from 'next-translate/setLanguage';
import { SpacedContainer } from 'revibe-ui';

type Props = {
  onChange?: (language: SupportedLocale) => void;
};

export const LanguageSelector = ({ onChange }: Props) => {
  const { language } = useTranslation();
  const router = useRouter();

  const handleLanguageChange = async (language: SupportedLocale) => {
    onChange && onChange(language);
    await setLanguage(language);
    router.reload();
  };

  return (
    <SpacedContainer type="horizontal" spacing="small">
      <p
        className={cn(
          'hover:underline',
          language === SUPPORTED_LOCALES.EN && 'underline'
        )}
        onClick={() => handleLanguageChange(SUPPORTED_LOCALES.EN)}
      >
        EN
      </p>
      <p>&#183;</p>
      <p
        className={cn(
          'hover:underline',
          language === SUPPORTED_LOCALES.FR && 'underline'
        )}
        onClick={() => handleLanguageChange(SUPPORTED_LOCALES.FR)}
      >
        FR
      </p>
      <p>&#183;</p>
      <p
        className={cn(
          'hover:underline',
          language === SUPPORTED_LOCALES.IT && 'underline'
        )}
        onClick={() => handleLanguageChange(SUPPORTED_LOCALES.IT)}
      >
        IT
      </p>
    </SpacedContainer>
  );
};
