import { MarketplaceAPI } from 'revibe-api';

// https://developers.klaviyo.com/en/v1-2/docs/guide-to-integrating-a-platform-without-a-pre-built-klaviyo-integration#active-on-site-tracking-snippet

export const KLAVIYO_EVENT = {
  VIEW_ITEM: 'Viewed Product',
  ADD_TO_CART: 'Added to Cart',
  INITIATED_CHECKOUT: 'Started Checkout',
} as const;

type KlaviyoItem = {
  ProductName: string;
  ProductID: string;
  SKU: string;
  Categories: string[];
  ImageURL: string;
  URL: string;
  Brand: string;
  Price: number;
  CompareAtPrice: number;
};

type KlaviyoCartItem = {
  ProductName: string;
  ProductID: string;
  SKU: string;
  Price: number;
  Quantity: number;
  RowTotal: number;
  ProductCategories: string[];
  ProductURL: string;
};

namespace KlaviyoPayload {
  export type ViewItem = KlaviyoItem;

  export type AddToCart = {
    $value: number;
    AddedItemProductName: string;
    AddedItemProductID: string;
    AddedItemSKU: string;
    AddedItemCategories: string[];
    AddedItemImageURL: string;
    AddedItemURL: string;
    AddedItemPrice: number;
    AddedItemQuantity: number;
    ItemNames: string[];
    CheckoutURL: string;
    Items: KlaviyoCartItem[];
  };

  export type InitiatedCheckout = {
    $event_id: string;
    $value: number;
    ItemNames: string[];
    CheckoutURL: string;
    Categories: string[];
    Items: KlaviyoCartItem[];
  };
}

export type KlaviyoEvent =
  | {
      event: typeof KLAVIYO_EVENT.VIEW_ITEM;
      payload: KlaviyoPayload.ViewItem;
    }
  | {
      event: typeof KLAVIYO_EVENT.ADD_TO_CART;
      payload: KlaviyoPayload.AddToCart;
    }
  | {
      event: typeof KLAVIYO_EVENT.INITIATED_CHECKOUT;
      payload: KlaviyoPayload.InitiatedCheckout;
    };

export const itemToKlaviyoItem = (
  item: MarketplaceAPI.Item & { list?: string }
): KlaviyoItem => {
  return {
    ProductID: String(item.id),
    ProductName: item.name,
    Categories: [item.category.name],
    URL: `www.revibe-upcycling.com/shop/item/${item.slug}`,
    SKU: String(item.id),
    Price: item.selling_price || item.price,
    Brand: item.seller.name,
    CompareAtPrice: item.selling_price || item.price,
    ImageURL: item.photos[0].medium_link,
  };
};

export const cartItemToKlaviyoCartItem = (
  item: MarketplaceAPI.CartItem
): KlaviyoCartItem => {
  return {
    ProductID: String(item.id),
    ProductName: item.details.name,
    ProductCategories: [item.details.category.name],
    ProductURL: `www.revibe-upcycling.com/shop/item/${item.details.slug}`,
    SKU: String(item.id),
    Price: item.discounted_price || item.price,
    Quantity: item.amount,
    RowTotal: item.discounted_price || item.price,
  };
};
