import { MarketplaceAPI } from 'revibe-api';

export const GA_EVENT = {
  VIEW_ITEM_LIST: 'view_item_list',
  SELECT_ITEM: 'select_item',
  VIEW_ITEM: 'view_item',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  VIEW_CART: 'view_cart',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_PAYMENT_INFO: 'add_payment_info',
  PURCHASE: 'purchase',
} as const;

type GACommerceItem = {
  item_id: string;
  item_name: string;
  affiliation?: string;
  item_brand: string;
  item_category: string;
  price: number;
  quantity: number;
  item_variant?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_list_id?: string;
  item_list_name?: string;
};

namespace GAPayload {
  export type ViewItemList = {
    item_list_id: string;
    item_list_name: string;
    items: GACommerceItem[];
  };

  export type SelectItem = {
    item_list_id: string;
    item_list_name: string;
    items: GACommerceItem[];
  };

  export type ViewItem = {
    currency: string;
    value: number;
    items: GACommerceItem[];
  };

  export type AddToCart = {
    currency: string;
    value: number;
    items: GACommerceItem[];
  };

  export type RemoveFromCart = {
    currency: string;
    value: number;
    items: GACommerceItem[];
  };

  export type ViewCart = {
    currency: string;
    value: number;
    items: GACommerceItem[];
  };

  export type AddToWishlist = {
    currency: string;
    value: number;
    items: GACommerceItem[];
  };

  export type BeginCheckout = {
    currency: string;
    value: number;
    items: GACommerceItem[];
    coupon?: string;
  };

  export type AddShippingInfo = {
    currency: string;
    value: number;
    coupon?: string;
    shipping_tier: 'standard' | 'express';
    items: GACommerceItem[];
  };

  export type AddPaymentInfo = {
    currency: string;
    value: number;
    coupon?: string;
    payment_type: string;
    items: GACommerceItem[];
  };

  export type Purchase = {
    currency: string;
    value: number;
    coupon?: string;
    transaction_id: string;
    shipping: number;
    tax: number;
    items: GACommerceItem[];
  };
}

export type GAEvent =
  | {
      event: typeof GA_EVENT.VIEW_ITEM_LIST;
      payload: GAPayload.ViewItemList;
    }
  | {
      event: typeof GA_EVENT.SELECT_ITEM;
      payload: GAPayload.SelectItem;
    }
  | {
      event: typeof GA_EVENT.VIEW_ITEM;
      payload: GAPayload.ViewItem;
    }
  | {
      event: typeof GA_EVENT.ADD_TO_CART;
      payload: GAPayload.AddToCart;
    }
  | {
      event: typeof GA_EVENT.REMOVE_FROM_CART;
      payload: GAPayload.RemoveFromCart;
    }
  | {
      event: typeof GA_EVENT.VIEW_CART;
      payload: GAPayload.ViewCart;
    }
  | {
      event: typeof GA_EVENT.ADD_TO_WISHLIST;
      payload: GAPayload.AddToWishlist;
    }
  | {
      event: typeof GA_EVENT.BEGIN_CHECKOUT;
      payload: GAPayload.BeginCheckout;
    }
  | {
      event: typeof GA_EVENT.ADD_SHIPPING_INFO;
      payload: GAPayload.AddShippingInfo;
    }
  | {
      event: typeof GA_EVENT.ADD_PAYMENT_INFO;
      payload: GAPayload.AddPaymentInfo;
    }
  | {
      event: typeof GA_EVENT.PURCHASE;
      payload: GAPayload.Purchase;
    };

export const itemToGACommerceItem = (
  item: MarketplaceAPI.Item & { list?: string }
): GACommerceItem => {
  return {
    item_id: String(item.id),
    item_name: item.name,
    item_brand: item.seller.name,
    item_category: item.category.name,
    price: item.price,
    item_list_id: item.list,
    item_list_name: item.list,
    discount: item.selling_price ? item.price - item.selling_price : 0,
    quantity: 1,
  };
};

export const cartItemToGACommerceItem = (
  item: MarketplaceAPI.CartItem & { list?: string }
): GACommerceItem => {
  return {
    item_id: String(item.id),
    item_name: item.details.name,
    item_brand: item.details.seller.name,
    item_category: item.details.category.name,
    price: item.price,
    item_list_id: item.list,
    item_list_name: item.list,
    discount: item.discounted_price ? item.price - item.discounted_price : 0,
    quantity: 1,
  };
};

export const orderItemToGACommerceItem = (
  item: MarketplaceAPI.OrderItem & { list?: string }
): GACommerceItem => {
  return {
    item_id: String(item.id),
    item_name: item.name,
    item_brand: item.seller.name,
    item_category: item.category.name,
    price: item.selling_price || item.price,
    item_list_id: item.list,
    item_list_name: item.list,
    discount: item.selling_price ? item.price - item.selling_price : 0,
    quantity: 1,
  };
};
