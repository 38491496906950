import { User, UserCreation, UserUpdate } from './users.types';
import { apiCall } from '../../../utils/apiCall';
import { SupportedLocale } from '../../../i18n/locales';

export const checkUserExistance = async (
  email: string,
  language: SupportedLocale
) =>
  apiCall({
    endpoint: `marketplace/users/check/${email}`,
    method: 'GET',
    reshaper: (data: { exists: boolean; user?: User }) => data,
    language,
  });

export const createUser = async (
  user: UserCreation,
  language: SupportedLocale
) =>
  apiCall({
    endpoint: 'marketplace/users',
    method: 'POST',
    body: { ...user, language },
    reshaper: (data: User) => data,
    language,
  });

export const getUser = async (userID: string, language: SupportedLocale) =>
  apiCall({
    endpoint: `marketplace/users/${userID}`,
    method: 'GET',
    reshaper: (data: User) => data,
    language,
  });

export const updateUser = async (
  userID: string,
  user: UserUpdate,
  language: SupportedLocale
) =>
  apiCall({
    endpoint: `marketplace/users/${userID}`,
    method: 'PUT',
    body: user,
    reshaper: (data: any) => data,
    language,
  });

export const deleteUser = async (userID: string, language: SupportedLocale) =>
  apiCall({
    endpoint: `marketplace/users/${userID}`,
    method: 'DELETE',
    reshaper: (data: any) => data,
    language,
  });

export const subscribeUserToNewsletter = async ({
  email,
  language,
}: {
  email: string;
  language: SupportedLocale;
}) =>
  apiCall({
    endpoint: 'marketplace/marketing/newsletter-subscribe',
    method: 'POST',
    body: { email, language },
    reshaper: (data: any) => data,
    language,
  });
